<template>  
  <div class="side-nav-inner-toolbar">
    <DxDrawer
      class="drawer"
      position="before"
      template="menuTemplate"
      v-model:opened="drawerOpenState"
      openedStateMode="shrink"
      :closeOnOutsideClick="false"
    >   
      <DxToolbar>
        <DxItem
          location="before"
          locate-in-menu="never"
          :options="{ type: 'default' }"
        >
          <img            
            src="/logo.png"            
            style="cursor: pointer;margin-left: 10px;"
            :height="30"
            @click="logoClick()"
          />
        </DxItem>
        <DxItem
          :options="solMenuGosterGizleButtonOptions()"
          location="before"
          widget="dxButton"
        />

        <DxItem
        v-if="aiSqlBuilderVisible"
          :options="aiSqlBuilderButtonOptions()"
          location="after"
          locate-in-menu="auto"
          widget="dxButton"
        />

        <DxItem
        v-if="dashboardDesignerVisible"
          :options="dashboardButtonOptions()"
          location="after"
          locate-in-menu="auto"
          widget="dxButton"
        />

        <DxItem
        v-if="reportDesignerVisible"
          :options="reportButtonOptions()"
          location="after"
          locate-in-menu="auto"
          widget="dxButton"
        />

        <!-- <DxItem
          :options="bildirimButtonOptions()"
          location="after"
          locate-in-menu="auto"
          widget="dxButton"
        /> -->

        <DxItem location="after" locate-in-menu="never">
          <div class="toolbar-label">{{ userName }}</div>
        </DxItem>
        <DxItem
          :options="cikisButtonOptions()"
          location="after"
          locate-in-menu="auto"
          widget="dxButton"
        />
      </DxToolbar>

      <div class="dyn-container">
        <dx-scroll-view
          :elementAttr="scrollViewAttribute"
          ref="scrollViewRef"
          class="layout-body with-footer"
        >
          <DxTabPanel
            ref="tabPanelElement"
            :repaintChangesOnly="true"
            item-title-template="title"
            item-template="itemTemplate"
            v-model:selected-index="tabPanelStore.selectedIndex"
            :elementAttr="tabPanelAttribute"
            noDataText="<<< İŞLEM YAPMAK İSTEDİĞİNİZ MENÜYÜ SEÇİNİZ >>>"
            :swipeEnabled="false"
            :animationEnabled="true"
            :showNavButtons="true"
          >
            <template #title="{ data: item }">
              <div style="display: flex; ">
                <div class="karakter-sinirla">
                  <span :title="item.pageTitle">{{ item.pageTitle }}</span>
                </div>
                <i
                  class="dx-icon dx-icon-close"
                  @click="tabCloseButtonClick(item)"
                />
              </div>
            </template>
            <template #itemTemplate="{ data: item }">
              <div
                class="dyn-tab-page-content"
                :id="'dynTabPage' + item.pageId"
                :style="
                  'min-height:' +(windowInnerHeight-58 ) +'px; padding-bottom:80px;'
                "
              >              
                <Page1Component
                  v-if="item.pageComponent == 'PageComponent'"
                  :page_id="item.pageId"
                  :page_title="item.pageTitle"
                />

                <PagePdfDisplayComponent
                  v-if="item.pageComponent == 'PagePdfDisplayComponent'"
                  :dosya_id="item.dosya_id"
                  :dosya_guid="item.dosya_guid"
                />

                <PageMatbuFormPdfDisplayComponent
                  v-if="item.pageComponent == 'PageMatbuFormPdfDisplayComponent'"
                  :grid_button_id="item.grid_button_id"
                  :dosya_id="item.dosya_id"
                  :dosya_guid="item.dosya_guid"
                />

                <PageEbysPdfDisplayComponent
                  v-if="item.pageComponent == 'PageEbysPdfDisplayComponent'"
                  :belge_id="item.belge_id"
                  :table_row_guid="item.table_row_guid"
                />

                <PageReportDisplayComponent v-if="item.pageComponent == 'PageReportDisplayComponent'" :report_url="item.report_url" :sag_panel="item.sag_panel"/>

                <PageReportDesignerComponent v-if="item.pageComponent == 'PageReportDesignerComponent'" :report_url="item.report_url"/>

                <MasterDetailGridComponent
                  v-if="item.pageComponent == 'MasterDetailGridComponent'"
                  :masterElementId="item.masterElementId"
                  :masterRowItem="item.masterRowItem"
                  :masterKeyValue="item.masterKeyValue"
                  :pageId="item.pageId"
                  :isDikeyTab="item.isDikeyTab"
                />

                <AiSqlBuilderComponent v-if="item.pageComponent=='AiSqlBuilderComponent'"/>

                <DashboardDesignerComponent v-if="item.pageComponent=='DashboardDesignerComponent'"/>

                <DashboardViewerComponent v-if="item.pageComponent=='DashboardViewerComponent'" :id="item.dashboardId"/>

              </div>
            </template>
          </DxTabPanel>
        </dx-scroll-view>
      </div>
      <template #menuTemplate>
        <SolMenuComponent :pWindowInnerHeight="windowInnerHeight" />
      </template>
    </DxDrawer>
  </div>

  <div
    class="offcanvas offcanvas-end"
    tabindex="-1"
    id="offcanvasRight"
    aria-labelledby="offcanvasRightLabel"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasRightLabel">BİLDİRİMLER</h5>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        onclick="document.getElementById('offcanvasRight').classList.remove('show');"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body"></div>
  </div>
</template>

<script>
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import DxDrawer from "devextreme-vue/drawer";
import DxScrollView from "devextreme-vue/scroll-view";
import DxTabPanel from "devextreme-vue/tab-panel";
import SolMenuComponent from "../components/sol-menu";
import { ref } from "vue";
import { useTabPanelStore } from "@/services/tab-panel.store";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/services/auth.store";

export default {
  setup() {
   
    const router = useRouter();
    const authStore = useAuthStore();
    const drawerOpenState = ref(true);

    const tabPanelStore = useTabPanelStore();
    const tabComponentContainer = ref();
    const tabPanelPageItems = ref(tabPanelStore.getItems);
    const tabPanelElement = ref();
    const tabsElement = ref();
    const scrollViewRef = ref(null);
    const windowInnerHeight = ref(window.innerHeight);

    const aiSqlBuilderVisible = ref(authStore.aiSqlBuilderVisible);
    const dashboardDesignerVisible = ref(authStore.dashboardDesignerVisible);
    const reportDesignerVisible = ref(authStore.reportDesignerVisible);
    
    
    const userName = ref(authStore.getUser.adSoyad);

    function tabCloseButtonClick(item) {
      tabPanelStore.remove(item);
    }

    tabPanelStore.$subscribe((mutation, state) => {      
      tabPanelElement.value.instance.option("items", state.pages);
    });

    function solMenuGosterGizleButtonOptions() {
      return {
        icon: "menu",
        type: "default",
        onClick: () => {
          drawerOpenState.value = !drawerOpenState.value;
        },
      };
    }

    function logoClick() {
      drawerOpenState.value = !drawerOpenState.value;
    }

    function cikisButtonOptions() {
      return {
        icon: "bi bi-box-arrow-right",
        elementAttr:{
          style:'margin-right:10px;'
        },
        onClick: () => {
          authStore.logout().then(() => {
            router.push("/login");
          });
        },
      };
    }

    function bildirimButtonOptions() {
      return {
        icon: "bi bi-chat-left-dots-fill",
        type: "default",
        hint: "Bildirimler",
        text: "15",
        onClick: () => {
          document.getElementById("offcanvasRight").classList.add("show");
        },
      };
    }

    function aiSqlBuilderButtonOptions() {
      return {
        icon: "bi bi-boxes",
        type: "default",
        hint: "AI SQL BUILDER",        
        onClick: () => {
          tabPanelStore.aiSqlBuilder();
        },
      };
    }

    function dashboardButtonOptions() {
      return {
        icon: "bi bi-graph-up-arrow",
        type: "default",
        hint: "Dashboard Designer",        
        onClick: () => {
          tabPanelStore.dashboardDesigner();
        },
      };
    }


    function reportButtonOptions() {
      return {
        icon: "bi bi-file-earmark-bar-graph",
        type: "default",
        hint: "Report Designer",        
        onClick: () => {
          tabPanelStore.reportDesigner();
        },
      };
    }    
    

    return {
      logoClick,
      solMenuGosterGizleButtonOptions,
      cikisButtonOptions,
      bildirimButtonOptions,
      aiSqlBuilderButtonOptions,
      dashboardButtonOptions,
      reportButtonOptions,
      userName,
      drawerOpenState,
      tabPanelStore,
      tabsElement,
      scrollViewRef,
      tabPanelPageItems,
      tabCloseButtonClick,
      tabPanelElement,
      tabComponentContainer,
      scrollViewAttribute: {
        //ismi değiştirme, alt nesnelerde bu isim ile nesneye erişim sağlanıyor
        id: "pageLayoutScrollView",
      },
      tabPanelAttribute: {
        class: "dyn-tab-panel",
      },
      windowInnerHeight,
      aiSqlBuilderVisible,
      dashboardDesignerVisible,
      reportDesignerVisible
    };
  },
  components: {
    DxToolbar,
    DxItem,
    DxDrawer,
    DxScrollView,
    DxTabPanel,
    SolMenuComponent,
  },
};
</script>

<style lang="scss">
.side-nav-inner-toolbar {
  width: 100%;
}

#navigation-header {
  @import "../themes/generated/variables.additional.scss";
  background-color: $base-accent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .menu-button .dx-icon {
    color: $base-text-color;
  }

  .screen-x-small & {
    padding-left: 20px;
  }

  .dx-theme-generic & {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.dx-tab-content .dx-icon.dx-icon-close {
  display: inline-block;
  opacity: 0.6;
  margin-right: 0;
  margin-left: 15px;
  font-size: 18px;
}

// .dyn-tab-panelxxxxx
//   .dx-tabpanel-tabs:not(.dx-popup-content .dx-tabpanel-tabs):not(
//     .dyn-tab-page-content .dx-tabpanel-tabs
//   ) {
//   position: fixed;
//   top: 0;
//   left: 250px;
//   width: 100%;
//   z-index: 1;
// }

.karakter-sinirla {
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dx-toolbar-items-container {
  border-bottom: 1px solid #e0e0e0;
}

.dyn-container {
  background-image: url("@/assets/btso_arka_fon.png");
  background-repeat: repeat;
}

.dx-empty-message {
  padding-top: 15px;
}
</style>
