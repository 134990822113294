import { defineStore } from "pinia";

export const useTabPanelStore = defineStore("tabPanel", {
  state: () => ({
    selectedIndex: 0,
    pages: [],
  }),
  getters: {
    getItems() {
      return this.pages.length == 0 ? [] : this.pages;
    },
  },
  actions: {

    setSelectedIndex(index){
      this.selectedIndex =index;
    },

    addPageComponent(pageId, pageTitle) {
      const page = { pageId, pageTitle, pageComponent: "PageComponent" };

      const existsPageIndex = this.getPageIndex(pageId);
      if (existsPageIndex > -1) {
        this.selectedIndex = existsPageIndex;
      } else {
        this.pages.push(page);
        this.selectedIndex = this.pages.length - 1;
      }
    },

    aiSqlBuilder(){
      console.log("aiSqlBuilder");
      const page = { 
        pageId:"ai-sql-builder",
        pageTitle:"AI SQL BUILDER",
        pageComponent: "AiSqlBuilderComponent"
      };
      const existsPageIndex = this.getPageIndex(page.pageId);
      if (existsPageIndex > -1) {
        this.selectedIndex = existsPageIndex;
      } else {
        this.pages.push(page);
        this.selectedIndex = this.pages.length - 1;
      }
    },

    addDashboardViewerPageComponent(pageId,pageTitle,dashboardId){
      
      const page = { 
        pageId:"dashboard-viewer-"+dashboardId,
        pageTitle:"Dashboard",
        pageComponent: "DashboardViewerComponent",  
        dashboardId:dashboardId      
      };
      const existsPageIndex = this.getPageIndex(page.pageId);
      if (existsPageIndex > -1) {
        this.selectedIndex = existsPageIndex;
      } else {
        this.pages.push(page);
        this.selectedIndex = this.pages.length - 1;
      }
    },

    dashboardDesigner(){
      
      const page = { 
        pageId:"dashboard-designer",
        pageTitle:"Dashboard Designer",
        pageComponent: "DashboardDesignerComponent",        
      };
      const existsPageIndex = this.getPageIndex(page.pageId);
      if (existsPageIndex > -1) {
        this.selectedIndex = existsPageIndex;
      } else {
        this.pages.push(page);
        this.selectedIndex = this.pages.length - 1;
      }
    },   

    pdfDisplay(guid,dosyaId,pageTitle){
      
      const page = { 
        pageId:guid,
        pageTitle:pageTitle,
        pageComponent: "PagePdfDisplayComponent" ,
        dosya_guid:guid,
        dosya_id:dosyaId
      };
      const existsPageIndex = this.getPageIndex(page.pageId);
      if (existsPageIndex > -1) {
        this.selectedIndex = existsPageIndex;
      } else {
        this.pages.push(page);
        this.selectedIndex = this.pages.length - 1;
      }
      
    },

    matbuFormPdfDisplay(gridButtonId,guid,dosyaId,pageTitle){
      
      const page = { 
        pageId:guid,
        pageTitle:pageTitle,
        pageComponent: "PageMatbuFormPdfDisplayComponent" ,
        grid_button_id:gridButtonId,
        dosya_guid:guid,
        dosya_id:dosyaId
      };
      const existsPageIndex = this.getPageIndex(page.pageId);
      if (existsPageIndex > -1) {
        this.selectedIndex = existsPageIndex;
      } else {
        this.pages.push(page);
        this.selectedIndex = this.pages.length - 1;
      }
      
    },


    ebysPdfDisplay(belgeId,pageTitle,tableRowGuid){
      

      const page = { 
        pageId:"ebys-"+belgeId,
        pageTitle:pageTitle,
        pageComponent: "PageEbysPdfDisplayComponent",        
        belge_id:belgeId,
        table_row_guid:tableRowGuid
      };

      console.log("page:",page);
      const existsPageIndex = this.getPageIndex(page.pageId);
      if (existsPageIndex > -1) {
        this.selectedIndex = existsPageIndex;
      } else {
        this.pages.push(page);
        this.selectedIndex = this.pages.length - 1;
      }
      
    },

    reportDesigner(){
      
      const page = { 
        pageId:"report-designer",
        pageTitle:"Report Designer",
        pageComponent: "PageReportDesignerComponent",        
      };
      const existsPageIndex = this.getPageIndex(page.pageId);
      if (existsPageIndex > -1) {
        this.selectedIndex = existsPageIndex;
      } else {
        this.pages.push(page);
        this.selectedIndex = this.pages.length - 1;
      }
    },   

    reportDisplay(report_url,pageTitle,sagPanel=false){
      
      const page = { 
        pageId:'report_display_'+report_url,
        pageTitle:pageTitle,
        pageComponent: "PageReportDisplayComponent",
        report_url:report_url,
        sag_panel:sagPanel ?? false
      };     

      const existsPageIndex = this.getPageIndex(page.pageId);
      if (existsPageIndex > -1) {
        this.selectedIndex = existsPageIndex;
      } else {
        this.pages.push(page);
        this.selectedIndex = this.pages.length - 1;
      }

    },

    addMasterDetailGridComponent(
      masterElementId,
      masterKeyValue,
      masterRowItem,
      pageTitle,
      rootPageId,
      rootPageTitle,
      isDikeyTab
    ) {     

      const page = {
        pageId: rootPageId + "-" + masterElementId + "-" + masterKeyValue,
        pageTitle: rootPageTitle + " - " + pageTitle,
        pageComponent: "MasterDetailGridComponent",
        rootPageId,
        rootPageTitle,
        masterKeyValue,
        masterElementId,
        masterRowItem,
        isDikeyTab
      };
      const existsPageIndex = this.getPageIndex(page.pageId);
      if (existsPageIndex > -1) {
        this.selectedIndex = existsPageIndex;
      } else {
        this.pages.push(page);
        this.selectedIndex = this.pages.length - 1;
      }
    },

    getPageIndex(pageId) {
      const existsPage = this.pages.find((d) => d.pageId == pageId);
      return this.pages.indexOf(existsPage);
    },

    remove(page) {
      const index = this.pages.indexOf(page);
      this.pages = this.pages.filter((e) => e !== page);
      this.selectedIndex = index > 0 ? index - 1 : 0;
    },
  },
});
