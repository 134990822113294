<template>
  <div id="root">   
    <div class="app screen-large">
      <component :is="$route.meta.layout">
        <div class="content">
          <router-view :key="$route.fullPath" />
        </div>
        <template #footer>
          <app-footer />
        </template>
      </component>
    </div>
  </div>
</template>

<script>

import { onMounted } from "vue";
import * as signalR from "@microsoft/signalr";
import CoreService from "@/services/core.service";
import trMessages from 'devextreme/localization/messages/tr.json';
import { locale,loadMessages  } from "devextreme/localization";
import notify from "devextreme/ui/notify";

export default {
  components: {
  },
  setup() {

    console.log("APP");   

    loadMessages(trMessages);
    locale("tr-TR");

    const connection = new signalR.HubConnectionBuilder()
        
      .withUrl(CoreService.getApiUrl()+"notification",{withCredentials: false})      
      .withAutomaticReconnect()      
      .build();

    console.log("connection:", connection);

    connection.on("notify", (message) => {
      notify(
          {
            message: message,
            type: "success",
          },
          { position: "bottom right", direction: "up-push" }
        );
      console.log("notify:", message);
    });
    

    onMounted(() => {
      connection
        .start()
        .then(() => {
          console.log("SignalR bağlantısı başarıyla kuruldu.");
        })
        .catch((error) => {
          console.error("SignalR bağlantısı kurulurken hata oluştu:", error);
        });
    });

    return {};
  },
};
</script>

<style lang="scss">
html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.app {
  @import "./themes/generated/variables.base.scss";
  background-color: darken($base-bg, 5);
  display: flex;
  height: 100%;
  width: 100%;
}

.dx-field-item-help-text {
  color: rgb(255, 0, 0);
}

.dx-field-item-label-text {
  font-weight: bold;
}

.dx-texteditor.dx-editor-filled.dx-state-disabled, 
.dx-texteditor.dx-editor-filled.dx-state-readonly{
  background-color: rgba(0,0,0,.04) !important;
}
</style>
