<template :key="guid">
  <div v-if="refreshCalendar" style="display: flex">    
    <div style="margin: 5px;"
      class="card"
      v-for="root in calendarDataSource"
      :key="'root_' + root.id"
    >
      <div class="card-body">
        <strong>{{ root.text }}</strong>
        <DxCalendar
          :key="'detay_' + root.id"
          zoomLevel="month"
          cellTemplate="custom"
          :showWeekNumbers="true"
          dateSerializationFormat="yyyy-MM-dd"
          @valueChanged="onValueChanged"
          v-model:value="selectedDate"
        >
          <template #custom="{ data: cell }">
            <div
              v-if="
                cell.date &&
                root.data.filter(
                  (d) =>
                    d.yil == cell.date.getFullYear() &&
                    d.ay == cell.date.getMonth() + 1 &&
                    d.gun == cell.date.getDate()
                ).length > 0
              "
            >
              <span class="etkinlik-tarihi">
                {{ cell.text }}
              </span>
            </div>

            <span v-else>
              {{ cell.text }}
            </span>
          </template>          
        </DxCalendar>        
      </div>
    </div>
  </div>
  
</template>

<script>
import { ref, reactive } from "@vue/reactivity";
import { watch } from "vue";
import CoreService from "@/services/core.service";
import DxCalendar from "devextreme-vue/calendar";

export default {
  components: {
    DxCalendar,
  },
  props: {
    parametreFormItem: {
      type: Object,
      required: true,
    },
    parametreFormItemData: {
      type: Object,
      required: true,
    },
    formDataItem: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const guid = ref(CoreService.getRandomGuid());
    const defaultData = { "id": 0, "text": null, "data": [] };
    let calendarDataSource = ref([defaultData]);
    
    let parametreFormDataItem = reactive(props.formDataItem);
    const parametreItem = reactive(props.parametreFormItem);
    const refreshCalendar = ref(true);
    const selectedDate = ref();

    function onValueChanged(e) {
      parametreFormDataItem[parametreItem.parameter_name] = e.value;  
      //const [year, month, day] = e.value.split('-');
      //selectedDate.value = new Date(parseInt(year),parseInt(month-1),parseInt(day)); //`${day}.${month}.${year}`
    }

    //calendar alanı hariç tüm parametre form property değerleri değiştiği zaman calendar nesnesi değerleri yeniden alınır
    Object.keys(parametreFormDataItem).forEach((key) => {
      if (key !== parametreItem.parameter_name) {
        watch(
          () => parametreFormDataItem[key],
          () => {
            CoreService.post(
              "GetParameterCalendarData/" +
                parametreItem.dyn_data_grid_parametre_form_item_id,
              { parametreJson: parametreFormDataItem }
            ).then((result) => {
              
              refreshCalendar.value = false;
              if (result.success) {
                calendarDataSource.value = JSON.parse(result.result) ?? [defaultData];
                refreshCalendar.value = true;
                // setTimeout(function () {
                //   refreshCalendar.value = true;
                // }, 50);
              }
            });
          }
        );
      }
    });


    return {
      guid,
      parametreItem,
      onValueChanged,
      refreshCalendar,
      calendarDataSource,
      selectedDate
    };
  },
};
</script>

<style scoped>
.etkinlik-tarihi{
  color: white; font-weight: bold; background-color: green;
}
</style>
