
import { useAuthStore } from "@/services/auth.store";

// import "./themes/generated/theme.base.css";
// import "./themes/generated/theme.additional.css";

import "ace-builds/css/ace.css";
import "ace-builds/css/theme/dreamweaver.css";
import "ace-builds/css/theme/ambiance.css";

import "devexpress-richedit/dist/dx.richedit.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.common.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.material.orange.light.css";
import "@devexpress/analytics-core/dist/css/dx-querybuilder.css";
import "devexpress-dashboard/dist/css/dx-dashboard.material.orange.light.compact.min.css";

import "bootstrap5/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.min.css";

import { createApp } from "vue";
import { createPinia } from "pinia";
import router from "./router";
import themes from "devextreme/ui/themes";
import App from "./App";
import CoreService from "@/services/core.service";

import DataGrid from "devextreme/ui/data_grid";
import TextArea from "devextreme/ui/text_area";
import Page1Component from "@/views/page-1.vue";
import PagePdfDisplayComponent from "@/views/page-pdf-display.vue";
import PageMatbuFormPdfDisplayComponent from "@/views/page-matbu-form-pdf-display.vue";
import PageEbysPdfDisplayComponent from "@/views/page-ebys-pdf-display.vue";
import PageReportDisplayComponent from "@/views/page-report-display.vue";
import PageReportDesignerComponent from "@/views/page-report-designer.vue";
import GridComponent from "@/views/grid/grid-page";
import PopupFormComponent from "@/views/grid/grid-popup-form";
import PopupFormGroupComponent from "@/views/grid/grid-popup-form-group";
import PopupFormItemComponent from "@/views/grid/grid-popup-form-item";
import PopupFormItemHtmlEditorComponent from "@/views/grid/form-item-html-editor.vue";
import PopupFormItemImageEditorComponent from "@/views/grid/form-item-image-editor.vue";
import PopupFormItemMultipleGridComponent from "@/views/grid/form-item-multiple-grid.vue";
import MatbuFormSablonComponent from "@/views/grid/matbu-form-sablon.vue";
import MatbuFormComponent from "@/views/grid/matbu-form.vue";
import MasterDetailGridComponent from "@/views/grid/master-detail-grid";
import MasterDetailGridPopupComponent from "@/views/grid/master-detail-grid-popup";
import GridDetayFormComponent from "@/views/grid/grid-detay-form";
import ParametreFormComponent from "@/views/grid/grid-parametre-form";
import ParametreFormItemCalendarComponent from "@/views/grid/parametre-form-item-calendar.vue";
import DropDownPopupComponent from "@/views/grid/drop-down-popup.vue";
import CsvFileUploaderPopupComponent from "@/views/grid/csv-file-uploader-popup.vue";
import FileUploaderPopupComponent from "@/views/grid/file-uploader-popup.vue";
import EbysDosyaYuklePopupComponent from "@/views/grid/ebys-dosya-yukle-popup.vue";
import EbysEkDosyaYuklePopupComponent from "@/views/grid/ebys-ek-dosya-yukle-popup.vue";


import DropDownGridComponent from "@/views/grid/drop-down-grid-component.vue";
import DropDownParametreComponent from "@/views/grid/drop-down-parametre.vue";
import FormDesignerComponent from "@/components/form-designer.vue";
import DashboardDesignerComponent from "@/components/dashboard-designer-control.vue";
import DashboardViewerComponent from "@/components/dashboard-viewer-control.vue";
import AiSqlBuilderComponent from "@/components/ai-sql-builder.vue";

const app = createApp(App);
app.use(createPinia());

const loadTheme = async (themeName) => {
  try {
    // CSS dosyasını dinamik olarak yükle
    await import(`./themes/generated/theme.${themeName}.css`);
    console.log(`${themeName} teması yüklendi.`);
  } catch (error) {
    console.error("Tema yüklenirken hata oluştu:", error);
  }
};
const store = useAuthStore();
loadTheme(store.getThemeName);
loadTheme("additional");


//dx datagrid için default ayarlar uygulanıyor
DataGrid.defaultOptions({
  options: {
    dateSerializationFormat: "yyyy-MM-dd",
    columnAutoWidth: true,
    searchPanel: {
      visible: false,
      width: 300,
      placeholder: "",
    },
    pager: {
      visible: true,
    },
    paging: {
      pageSize: 10,
    },
    onRowUpdating: function (options) {
      let a = options.oldData;
      let b = options.newData;
      for (const key in b)
        if (Object.prototype.hasOwnProperty.call(b, key)) a[key] = b[key];
      options.newData = a;
    },
  },
});

TextArea.defaultOptions({
  options: {
    autoResizeEnabled: true,
    minHeight: "100px",
    maxHeight: "400px",
  },
});

// config.json dosyasını yüklemek için fetch kullanıyoruz
fetch("/config.json")
  .then((response) => response.json())
  .then((config) => {
    themes.initialized(() => {      

      app.provide('config', config);
      CoreService.setApiUrl(config.apiUrl);

      app.use(router);

      app.mount("#app");
      app.component("Page1Component", Page1Component);
      app.component("PagePdfDisplayComponent", PagePdfDisplayComponent);
      app.component("PageMatbuFormPdfDisplayComponent", PageMatbuFormPdfDisplayComponent);
      app.component("PageEbysPdfDisplayComponent", PageEbysPdfDisplayComponent);
      app.component("PageReportDisplayComponent", PageReportDisplayComponent);
      app.component("PageReportDesignerComponent", PageReportDesignerComponent);
      app.component("GridComponent", GridComponent);
      app.component("PopupFormComponent", PopupFormComponent);
      app.component("PopupFormGroupComponent", PopupFormGroupComponent);
      app.component("PopupFormItemComponent", PopupFormItemComponent);
      app.component("PopupFormItemHtmlEditorComponent",PopupFormItemHtmlEditorComponent);
      app.component("PopupFormItemImageEditorComponent",PopupFormItemImageEditorComponent);
      app.component("PopupFormItemMultipleGridComponent",PopupFormItemMultipleGridComponent);
      app.component("MatbuFormSablonComponent", MatbuFormSablonComponent);
      app.component("MatbuFormComponent", MatbuFormComponent);
      app.component("MasterDetailGridComponent", MasterDetailGridComponent);
      app.component("MasterDetailGridPopupComponent",MasterDetailGridPopupComponent);
      app.component("GridDetayFormComponent", GridDetayFormComponent);
      app.component("ParametreFormComponent", ParametreFormComponent);
      app.component("ParametreFormItemCalendarComponent",ParametreFormItemCalendarComponent);
      app.component("DropDownPopupComponent", DropDownPopupComponent);
      app.component("CsvFileUploaderPopupComponent",CsvFileUploaderPopupComponent);
      app.component("FileUploaderPopupComponent", FileUploaderPopupComponent);
      app.component("EbysDosyaYuklePopupComponent", EbysDosyaYuklePopupComponent);
      app.component("EbysEkDosyaYuklePopupComponent", EbysEkDosyaYuklePopupComponent);
      app.component("DropDownGridComponent", DropDownGridComponent);
      app.component("DropDownParametreComponent", DropDownParametreComponent);
      app.component("FormDesignerComponent", FormDesignerComponent);
      app.component("FormDesignerComponent", FormDesignerComponent);
      app.component("DashboardDesignerComponent", DashboardDesignerComponent);
      app.component("DashboardViewerComponent", DashboardViewerComponent);
      app.component("AiSqlBuilderComponent", AiSqlBuilderComponent);
      
    });
  })
  .catch((error) => {
    console.error("Config dosyası yüklenirken hata oluştu:", error);
  });
