<template :key="guid">
  <div v-if="!isLoaded">Loading...</div>
  <div v-else style="position: relative">
    <DxLoadPanel
      v-model:visible="loadPanelVisible"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      shading-color="rgba(0,0,0,0.4)"
      :container="'#dynTabPage' + tabPageId"
    />

    <small
      class="dx-field-item-label-text"
      style="
        position: absolute;
        top: 0px;
        right: 3px;
        z-index: 99;
        font-size: 10px;
      "
      >[{{ dataGridId }}]</small
    >

    <ParametreFormComponent
      ref="parametreFormRef"
      v-if="isParametreForm"
      :parametreForms="parametreForms"
      :parametreFormItems="parametreFormItems"
      :pageId="tabPageId"
      :gridItem="gridItem"
      :masterRowItem="masterGridRowItem"
      :parametreFormData="parametreFormData"
    />

    <DxDataGrid
      ref="gridElement"
      :dataSource="dataSource"
      :showBorders="true"
      :show-column-lines="true"
      :remoteOperations="remoteOperations"
      :allow-column-reordering="true"
      :export="{ enabled: exportEnabled }"
      :wordWrapEnabled="gridItem.word_wrap_enabled"
      :width="gridItem.width"
      :rowAlternationEnabled="!masterDetailTabPageEnabled && !gridItem.row_style_js_function"
      :hoverStateEnabled="masterDetailTabPageEnabled"
      @toolbarPreparing="onToolbarPreparing"
      @cellPrepared="onCellPrepared"
      @rowPrepared="onRowPrepared"
      @rowExpanding="onRowExpanding"
      @rowDblClick="onRowDblClick"
      @exporting="onExporting"
      
    >
      <DxEditing
        :allowUpdating="false"
        :allowAdding="false"
        :allowDeleting="false"
      />
      <DxFilterRow :visible="true" />
      <DxSorting :mode="sortingMode" />
      <DxPaging :page-size="gridItem.paging_page_size ?? 10" />


      <DxColumn  
        v-if="rowButtonGroups.length>0"
        caption=""
        cellTemplate="rowButtonGroupsTemplate"
      />
      <template #rowButtonGroupsTemplate="{ data }">      

       <DxDropDownButton
          v-for="item in rowButtonGroups"
          :key="item"          
          :dropDownOptions="{ width: item.width }"
          :text="item.caption"
          :hint="item.hint"
          :icon="item.icon"
          stylingMode="text"
          displayExpr="text"
          :showArrowIcon="false"          
          :visible="onRowButtonGroupVisible(data,item)"
        >
      <DxItem  
        v-for="buttonItem in rowButtonGroupItems.filter((d)=> d.dyn_data_grid_button_group_id == item.dyn_data_grid_button_group_id)"
          :key="buttonItem"
          :icon="buttonItem.icon"          
          :text="buttonItem.caption"
          :visible="onRowButtonGroupItemVisible(data,buttonItem)"
          @click="onRowButtonGroupItemClick(data, buttonItem)"/>
      
      </DxDropDownButton>
      
      </template>   


      <DxColumn
        type="buttons"        
        v-if="columnButtons.length > 0 || masterDetailTabPageEnabled || rowButtonGroups.length > 0"
      >

        <DxButton
          hint="DETAYLAR"
          icon="bi bi-plus-circle"
          @click="onButtonDetayClick"
          v-if="masterDetailTabPageEnabled"
        />


        <DxButton
          v-for="item in columnButtons"
          :key="item"
          :hint="item.caption"
          :icon="item.icon"
          :text="item.caption"
          :visible="onButtonVisible"
          @click="onButtonClick($event, item)"
        />
      </DxColumn>

      <DxColumn
        v-if="columnDropDownButtons.length > 0"
        caption=""
        :width="75"
        cellTemplate="groupButtonTemplate"
      />
      <template #groupButtonTemplate="{ data }">
        <DxDropDownButton
          :items="columnDropDownButtons"
          :dropDownOptions="{ width: 350 }"
          :wrapItemText="true"
          displayExpr="caption"
          keyExpr="dyn_data_grid_button_id"
          hint="Matbu Formlar"
          icon="doc"
          @itemClick="onGroupButtonItemClick($event, data)"
        />
      </template>

      <DxColumn
        v-for="item in columns"
        :key="'dxcolumn_' + item.dyn_data_grid_column_id"
        :dataField="item.data_field"
        :dataType="item.dx_data_type"
        :caption="item.caption"
        :format="item.format"
        :visible="isDetail ? item.detail_grid_visible : item.visible"
        :visibleIndex="item.visible_index"
        :name="item.data_field"
        :allowFiltering="item.allow_filtering"
        :allowSorting="item.allow_sorting"
        :allowExporting="item.allow_exporting"
        :sortOrder="item.sort_order"
        :sortIndex="item.sort_index"
        :alignment="item.alignment"
        :encodeHtml="false"
      />

      <DxColumnChooser
        :enabled="gridItem.column_chooser_enabled"
        mode="select"
      />

      <DxMasterDetail
        :enabled="masterDetailEnabled"
        template="masterDetailTemplate"
      />
      <template #masterDetailTemplate="{ data }">
        <MasterDetailGridComponent
          :masterElementId="dataGridId"
          :masterKeyValue="data.data[gridItem.key_expr]"
          :masterRowItem="data.data"
          :pageId="tabPageId"
          @callGridDetayPopupHidden="onGridDetayPopupHidden(data)"          
        />
      </template>

      <DxSummary>
        <DxTotalItem
          v-for="item in totalSummaryColumns"
          :column="item.data_field"
          :summaryType="item.summary_type"
          :valueFormat="item.value_format"
          :key="item"
        />
      </DxSummary>
    </DxDataGrid>

    <slot v-if="isPopupEnable" :key="'slot_grid_popup_form_' + guid">
      <PopupFormComponent
        :key="'grid_popup_form_' + guid"
        :pageId="tabPageId"
        :gridButtonId="popupGridButtonId"
        :gridButtonItem="popupGridButtonItem"
        :gridElement="gridElement"
        :popupDataItem="popupDataItem"
        :dataGridFormResult="dataGridFormResult"
        :gridColumns="columns"
        :masterItem="masterGridItem"
        :masterRowItem="masterGridRowItem"
        @callPopupHiddenEvent="onPopupHidden"
        @callGridRefresh="gridRefresh"        
      />
    </slot>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxSorting,
  DxButton,
  DxMasterDetail,
  DxColumnChooser,
  DxSummary,
  DxTotalItem,
  DxPaging,
} from "devextreme-vue/data-grid";
import {DxDropDownButton,DxItem} from "devextreme-vue/drop-down-button";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import CoreService from "@/services/core.service";
import { useTabPanelStore } from "@/services/tab-panel.store";
import { watch, ref, reactive, onMounted } from "vue";

import notify from "devextreme/ui/notify";
import hideToasts from "devextreme/ui/toast/hide_toasts";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";

export default {
  components: {
    DxDataGrid,
    DxMasterDetail,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxSorting,
    DxButton,
    DxColumnChooser,
    DxSummary,
    DxTotalItem,
    DxPaging,
    DxLoadPanel,
    DxDropDownButton,
    DxItem
  },
  props: {
    pageId: Number,
    isDetailGrid: {
      type: Boolean,
      required: true,
      default: false,
    },
    elementId: {
      type: Number,
      required: true,
    },
    filter: null,
    isDropDownGrid: {
      type: Boolean,
      default: false,
    },
    masterItem: {
      type: Object,
      default: () => {
        return { isEnable: false };
      },
    },
    masterRowItem: Object,
    gridSetupItem: Object,
  },
  setup(props) {
    const guid = ref(CoreService.getRandomGuid());
    const tabPageId = ref(props.pageId);
    const loadPanelVisible = ref(false);
    const parametreFormRef = ref(null);
    let parametreButtonOtoTetiklendi = false;

    const tabPanelStore = useTabPanelStore();

    const remoteOperations = ref(true);
    const masterGridItem = reactive(props.masterItem);
    const masterGridRowItem = reactive(props.masterRowItem);
    const dataGridId = parseInt(props.elementId);
    const isDetail = ref(props.isDetailGrid);
    const gridElement = ref();
    const gridItem = ref({});

    const masterDetailEnabled = ref(false);
    const masterDetailTabPageEnabled = ref(false);

    const exportEnabled = ref(false);

    const isParametreForm = ref(false);
    const parametreFormData = ref({});
    const parametreForms = ref([]);
    const parametreFormItems = ref([]);
    const sortingMode = ref("single");

    const isLoaded = ref(false);
    const columns = ref([]);
    const totalSummaryColumns = ref([]);
    const headerButtonGroups = ref([]);
    const headerButtonGroupItems = ref([]);
    const rowButtonGroups = ref([]);    
    const rowButtonGroupItems = ref([]);
    const columnButtons = ref([]);
    const columnDropDownButtons = ref([]);
    const toolbarButtons = ref([]);
    const toolbarDropDownButtons = ref([]);
    const parametreFormButtons = ref([]);
    const popupGridButtonId = ref();
    const popupGridButtonItem = ref();
    const popupDataItem = ref();
    const isPopupEnable = ref(false);

    const dataSource = ref();
    const dataGridFormResult = ref([]);

    CoreService.get("GetDataGrid/" + dataGridId).then((result) => {
      gridItem.value = result.grid[0] ?? {};

      //datasource function olanlarda pager pasif yapılır
      remoteOperations.value = gridItem.value.dyn_data_source_tip_id != 3;

      masterDetailEnabled.value =
        gridItem.value.master_detail_enabled && !props.isDropDownGrid;
      masterDetailTabPageEnabled.value =
        masterDetailEnabled.value == false &&
        gridItem.value.master_detail_tab_page_enabled;

      exportEnabled.value =
        gridItem.value.export_enabled && !props.isDropDownGrid;

      if (gridItem.value.multiple_sorting) sortingMode.value = "multiple";

      isParametreForm.value = result.parametreForms.length > 0;      

      if (isParametreForm.value === false) {
        const filter = props.filter ?? {};
        dataSource.value = CoreService.customStore(
          gridItem.value.key_expr,
          "GetList/" + dataGridId,
          filter
        );
      }

      parametreForms.value = result.parametreForms;
      parametreFormItems.value = result.parametreFormItems;

      columns.value = result.columns;

      for (var i in columns.value) {
        const colItem = columns.value[i];
        if (colItem.summary_types == null) continue;

        for (var j in colItem.summary_types) {
          const summaryItem = colItem.summary_types[j];
          totalSummaryColumns.value.push({
            data_field: colItem.data_field,
            summary_type: summaryItem,
            value_format: colItem.format,
          });
        }
      }

      const parametreFormButtonData = result.buttons.filter(
        (d) =>
          d.dyn_data_grid_button_konum_id == 0 &&
          d.dyn_data_grid_button_operasyon_tip_id > 0
      );
      const toolbarButtonData = result.buttons.filter(
        (d) =>
          d.dyn_data_grid_button_konum_id == 1 &&
          d.dyn_data_grid_button_operasyon_tip_id > 0
      );

      headerButtonGroups.value = result.buttonGroups.filter((d) => d.dyn_data_grid_button_konum_id == 1);
      rowButtonGroups.value = result.buttonGroups.filter((d) => d.dyn_data_grid_button_konum_id == 2);

      headerButtonGroupItems.value = result.buttons.filter((d) => d.dyn_data_grid_button_konum_id == 1 && d.dyn_data_grid_button_group_id != null);
      rowButtonGroupItems.value = result.buttons.filter((d) => d.dyn_data_grid_button_konum_id == 2 && d.dyn_data_grid_button_group_id != null);     
     

      const columnButtonData = result.buttons.filter(
        (d) =>
          d.dyn_data_grid_button_konum_id == 2 &&
          d.dyn_data_grid_button_group_id == null &&
          d.dyn_data_grid_button_operasyon_tip_id > 0
      );

      if (parametreFormButtonData) {
        parametreFormButtons.value = parametreFormButtonData;
      }

      if (toolbarButtonData) {
        /*
        12	REPORT_DISPLAY
        13	MATBU_FORM
        */
        toolbarButtons.value = toolbarButtonData.filter(
          (d) => ![12, 13].includes(d.dyn_data_grid_button_operasyon_tip_id)
        );
        toolbarDropDownButtons.value = toolbarButtonData.filter((d) =>
          [12, 13].includes(d.dyn_data_grid_button_operasyon_tip_id)
        );
      }    

      if (columnButtonData) {
        /*
        12	REPORT_DISPLAY
        13	MATBU_FORM
        */
        columnButtons.value = columnButtonData;
        

        /*
        columnButtons.value = columnButtonData.filter(
          (d) => ![12, 13].includes(d.dyn_data_grid_button_operasyon_tip_id)
        );
        columnDropDownButtons.value = columnButtonData.filter((d) =>
          [12, 13].includes(d.dyn_data_grid_button_operasyon_tip_id)
        );
        */

      }

      isLoaded.value = true;

      //PARAMETRe NESNELERİ OTOMETİK TETİKLEME
      const parametreButtonItem = parametreFormButtons.value[0];
      if (parametreButtonItem) {
        parametreFormItems.value.forEach((item) => {
          if (item.otomatik_tetikle) {
            watch(
              () => parametreFormData.value[item.parameter_name],
              () => {
                setTimeout(function () {
                  parametreButtonOnClick(parametreButtonItem);
                }, 150);
              }
            );
          }
        });
      }
    });

    function onToolbarPreparing(e) {
      //detay grid değil ise grid title eklenir
      if (!isDetail.value && gridItem.value.title != null) {
        e.toolbarOptions.items.unshift({
          location: "before",
          html: "<span>" + gridItem.value.title + "</span>",
        });
      }

      //parametre buton tanımlı değil ise refresh butonu otomatik eklenir
      //parametre button zaten refresh butonun işlevini yapar
      if (parametreFormButtons.value.length == 0) {
        e.toolbarOptions.items.unshift({
          location: "after",
          widget: "dxButton",
          options: {
            icon: "refresh",
            hint: "Yeniden Yükle",
            onClick: function () {
              e.component.refresh();
            },
          },
        });
      }

      e.toolbarOptions.items.unshift({
        location: "after",
        widget: "dxButton",
        options: {
          icon: "bi bi-filter-circle-fill",
          text: "FİLTRE TEMİZLE",
          hint: "TÜM FİLTRELERİ TEMİZLER VE DATAYI YENİDEN ÇEKER",
          onClick: function () {
            e.component.clearFilter();
            if (parametreFormRef.value) {
              parametreFormRef.value.callSetDefaultValuesFunction();
            } else {
              e.component.refresh();
            }
          },
        },
      });

      toolbarButtons.value.forEach(function (item) {
        e.toolbarOptions.items.unshift({
          location: "after",
          widget: "dxButton",
          options: {
            icon: item.icon,
            hint: item.caption,
            visible: onHeaderButtonVisible(item),
            onClick: function () {
              loadPanelVisible.value = true;
              CoreService.get(
                "GetDataGridForm/" + item.dyn_data_grid_button_id
              ).then((result) => {
                setTimeout(() => {
                  loadPanelVisible.value = false;
                  dataGridFormResult.value = result;
                  popupGridButtonId.value = item.dyn_data_grid_button_id;
                  popupGridButtonItem.value = item;
                  popupDataItem.value = null;
                  isPopupEnable.value = true;
                }, 250);
              });
            },
          },
        });
      });

      if (toolbarDropDownButtons.value.length > 0) {
        e.toolbarOptions.items.unshift({
          location: "after",
          widget: "dxDropDownButton",
          options: {
            items: toolbarDropDownButtons.value,
            dropDownOptions: { width: 350 },
            wrapItemText: true,
            displayExpr: "caption",
            keyExpr: "dyn_data_grid_button_id",
            icon: "doc",
            text: "MATBU FORM",
            onItemClick: function (obj) {
              const buttonData = obj.itemData;

              const id = buttonData.dyn_data_grid_button_id;
              CoreService.get("GetDataGridForm/" + id).then((result) => {
                dataGridFormResult.value = result;
                popupGridButtonId.value = id;
                //mevcut satırın bir clonu alınıyor
                //eğer nesnenin kendisi gönderilir ise form üzerinde kaydedilmeden yapılmış değişiklikler popup tekrar açıldığında korunuyor
                //bu durumun önüne geçmek için nesneye ait bir clone oluşturuluyor
                const cloneRowItem = Object.assign({}, masterGridRowItem);
                popupDataItem.value = cloneRowItem;
                isPopupEnable.value = true;
              });
            },
          },
        });
      }

      parametreFormButtons.value.forEach(function (item) {
        if (item.otomatik_tetikle && !parametreButtonOtoTetiklendi) {
          parametreButtonOtoTetiklendi = true;
          parametreButtonOnClick(item);
        }

        e.toolbarOptions.items.unshift({
          location: "after",
          widget: "dxButton",
          options: {
            icon: item.icon,
            text: item.caption,
            type: "default",
            onClick: function () {
              parametreButtonOnClick(item);
            },
          },
        });
      });
    }

    function onButtonDetayClick(e) {
      tabPanelStore.addMasterDetailGridComponent(
        dataGridId,
        e.row.key,
        e.row.data,
        e.row.data[gridItem.value.master_detail_tab_page_title_field_name],
        tabPageId.value,
        gridItem.value.title,
        gridItem.value.master_detail_tab_page_dikey_tab
      );
    }

    function onRowButtonGroupItemClick(rowData,buttonItem){      
      onButtonClick(rowData,buttonItem)
    }

    function onButtonClick(event, buttonItem) {

      const operasyonTipId = buttonItem.dyn_data_grid_button_operasyon_tip_id;
      const id = buttonItem.dyn_data_grid_button_id;

      if(buttonItem.button_click_before_procedure != null){
        CoreService.post("ButtonClickBeforeProcedure/" + id + "/" + event.row.key).then((result)=>{          
          if(result.success && buttonItem.button_click_before_procedure_reload_grid == true){
            gridRefresh();
          }
        });
      }

      if (operasyonTipId == 7 /*FILE_DOWNLOAD*/) {
        const prm =
          id + "/" + event.row.data.table_row_guid + "/" + event.row.data.dyn_dosya_id;
        const fullFileName = event.row.data.dosya_adi_full;
        CoreService.downloadFile("FileDownload/" + prm, fullFileName);
      } else if (operasyonTipId == 20 /*EBYS_FILE_DOWNLOAD*/) {
        const guid = event.row.data.table_row_guid ;
        const fullFileName = event.row.data.dosya_adi_full;
        CoreService.downloadFile("EbysFileDownload/" + guid, fullFileName);
      }else if (operasyonTipId == 14) {
        tabPanelStore.pdfDisplay(
          event.row.data.table_row_guid,
          event.row.data.dyn_dosya_id,
          event.row.data.dosya_adi
        );
      } else if (operasyonTipId == 17) {
        tabPanelStore.ebysPdfDisplay(
          event.row.data.belge_id,
          event.row.data.belge_no + " - EVRAK GÖRÜNTÜLEME",
          event.row.data.table_row_guid
        );
      } else if (operasyonTipId == 18) {
        tabPanelStore.matbuFormPdfDisplay(
          id,
          event.row.data.table_row_guid,
          event.row.data.dyn_dosya_id,
          event.row.data.dosya_adi
        );
      } else {
        CoreService.get("GetDataGridForm/" + id).then((result) => {
          dataGridFormResult.value = result;
          popupGridButtonId.value = id;
          popupGridButtonItem.value = buttonItem;
          //mevcut satırın bir clonu alınıyor
          //eğer nesnenin kendisi gönderilir ise form üzerinde kaydedilmeden yapılmış değişiklikler popup tekrar açıldığında korunuyor
          //bu durumun önüne geçmek için nesneye ait bir clone oluşturuluyor
          const cloneRowItem = Object.assign({}, event.row.data);
          popupDataItem.value = cloneRowItem;
          isPopupEnable.value = true;
        });
      }
    }

    function onGroupButtonItemClick(e, data) {
      const buttonData = e.itemData;
      const rowData = data.data;
      const id = buttonData.dyn_data_grid_button_id;

      CoreService.get("GetDataGridForm/" + id).then((result) => {
        dataGridFormResult.value = result;
        popupGridButtonId.value = id;
        popupGridButtonItem.value = buttonData;
        //mevcut satırın bir clonu alınıyor
        //eğer nesnenin kendisi gönderilir ise form üzerinde kaydedilmeden yapılmış değişiklikler popup tekrar açıldığında korunuyor
        //bu durumun önüne geçmek için nesneye ait bir clone oluşturuluyor
        const cloneRowItem = Object.assign({}, rowData);
        popupDataItem.value = cloneRowItem;
        isPopupEnable.value = true;
      });
    }

    function onHeaderButtonVisible(item) {
      if (item == null || item.visible_js_function == null) return true;

      const data = masterGridRowItem;

      if (data == null) return true;

      return eval(item.visible_js_function);
    }

    function onRowButtonGroupVisible(rowData,groupItem){
      
      let isVisible = false;

      const buttonItems = rowButtonGroupItems.value.filter((d)=> d.dyn_data_grid_button_group_id == groupItem.dyn_data_grid_button_group_id);

      for (let index = 0; index < buttonItems.length; index++) {
        const buttonItem = buttonItems[index];
        let visible = onRowButtonGroupItemVisible(rowData,buttonItem);
        if (visible) {
          isVisible = true;
          break;
        }
      }
      return isVisible;
    }

    function onRowButtonGroupItemVisible(rowData,buttonItem){

      if (buttonItem.visible_js_function == null) return true;

      //visible_js_function fonksiyonu içinden çağrılıp kullanılıyor
      const data = rowData.data;
      
      if (data == null) return true;      

      return eval(buttonItem.visible_js_function);
    }

    function onButtonVisible(e) {

      if (this.key.visible_js_function == null) return true;

      //visible_js_function fonksiyonu içinden çağrılıp kullanılıyor
      const data = e.row.data;

      if (data == null) return true;

      return eval(this.key.visible_js_function);
    }

    function onRowPrepared(e) {
      if (e.rowType != "data") return;
      if (gridItem.value.row_style_js_function) {
        const element = e.rowElement;
        const data = e.data;
        if (!element || !data) return;        
        eval(gridItem.value.row_style_js_function);
      }      
    }

    function onCellPrepared(e) {
      if (e.rowType != "data") return;

      const columnItem = columns.value.find(
        (d) => d.data_field == e.column.dataField
      );

      if (columnItem == null) return;

      if (columnItem.style_js_function != null) {
        const element = e.cellElement;
        const data = e.data;
        if (!element || !data) return;
        eval(columnItem.style_js_function);
      }

      //image tippinde bir grid kolonu ise img elementi eklenir
      if (columnItem.is_image) {
        e.cellElement.innerHTML = null;
        if (e.value == null) return;

        const img = document.createElement("img");
        img.src = e.value;
        img.style = "cursor:pointer;";
        img.className = "rounded img-thumbnail";
        img.addEventListener("click", function () {
          const imageIdFieldName = columnItem.data_field.replace("_base64", "");
          const dynImageId = e.data[imageIdFieldName];
          loadPanelVisible.value = true;
          CoreService.downloadFile(
            "ImageDownload/" + dynImageId,
            "Image_" + dynImageId
          ).then(() => {
            loadPanelVisible.value = false;
          });
        });
        e.cellElement.appendChild(img);
      }
    }

    function onRowExpanding(e) {
      if (gridItem?.value?.master_detail_row_expanding_procedure) {        
        CoreService.post(
          "GridDetayRowExpandingUpdate/" + dataGridId + "/" + e.key
        );
      }
      e.component.collapseAll(-1);
    }

    function onRowDblClick(e) {
      //master detail tab page olarak açılacak ise
      if (masterDetailTabPageEnabled.value) {
        tabPanelStore.addMasterDetailGridComponent(
          dataGridId,
          e.key,
          e.data,
          e.data[gridItem.value.master_detail_tab_page_title_field_name],
          tabPageId.value,
          gridItem.value.title,
          gridItem.value.master_detail_tab_page_dikey_tab
        );
      }
    }

    function onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Sayfa1");
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function (options) {
          options.excelCell.font = { name: "Arial", size: 12 };
          options.excelCell.alignment = { horizontal: "left" };
        },
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          const fileName = gridItem.value.title ?? "DataGrid";
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            fileName + ".xlsx"
          );
        });
      });
    }

    function gridRefresh() {
      gridElement.value.instance.refresh();
    }   

    function parametreButtonOnClick(item) {
      if (!parametreFormValidate()) {
        return;
      }

      const gridButtonId = item.dyn_data_grid_button_id;
      let parametreler = parametreFormData.value ?? {};
      if (props.filter !== null) {
        parametreler = { ...parametreler, ...props.filter };
      }

      //sadece WEB_SERVICE ve PROCEDURE tiplerinde popup açılır
      const operasyonTipId = item.dyn_data_grid_button_operasyon_tip_id;
      if ([4, 9].includes(operasyonTipId)) {
        loadPanelVisible.value = true;
        CoreService.get("GetDataGridForm/" + gridButtonId).then((result) => {
          /*
                    Popup form açılmadan önce, master gridden alınacak değerler var ise bu değerlerin property leri oluşturulup ataması yapılır.
                    Atama işleminden sonra property nesnelerinin hepsinin "visible" özelliği "false" ise  popup açılmadan direk sorgu çalıştırılır.
                    Bu özellik sadece web servis ve procedure  isteklerinde kullanılır
                  */

          const items = result.items;
          const visibleItemCount = items.filter(
            (d) => d.visible == true
          ).length;

          if (visibleItemCount > 0) {
            setTimeout(() => {
              loadPanelVisible.value = false;
              dataSource.value = CoreService.customStore(
                gridItem.value.key_expr,
                "GetList/" + dataGridId,
                parametreler
              );
              dataGridFormResult.value = result;
              popupGridButtonId.value = gridButtonId;
              popupDataItem.value = null;
              isPopupEnable.value = true;
            }, 250);
          } else {
            const masterValueItems = items.filter(
              (d) => d.set_value_from_master == true
            );
            let jsonData = {};
            if (masterValueItems.length > 0) {
              masterValueItems.forEach((valueItem) => {
                if (masterGridRowItem[valueItem.parameter_name] != null) {
                  jsonData[valueItem.parameter_name] =
                    masterGridRowItem[valueItem.parameter_name];
                }
              });
            }

            const url =
              operasyonTipId == 4 ? "PostDataGridForm/" : "GetWebService/";
            CoreService.post(url + gridButtonId, jsonData).then(
              (postResult) => {
                if (postResult.success) {
                  setTimeout(() => {
                    loadPanelVisible.value = false;
                    dataSource.value = CoreService.customStore(
                      gridItem.value.key_expr,
                      "GetList/" + dataGridId,
                      parametreler
                    );
                    gridRefresh();
                  }, 250);
                } else {
                  loadPanelVisible.value = false;
                  notify(
                    {
                      message: postResult.message,
                      type: "error",
                      displayTime: 10000,
                      minWidth: 150,
                    },
                    { position: "bottom center", direction: "up-push" }
                  );
                }
              }
            );
          }
        });
      } else {
        dataSource.value = CoreService.customStore(
          gridItem.value.key_expr,
          "GetList/" + dataGridId,
          parametreler
        );
      }
    }

    function parametreFormValidate() {
      let dizi = [];
      parametreFormItems.value
        .filter(
          (i) =>
            i.visible &&
            i.is_required &&
            i.dx_data_type != "boolean" &&
            i.multiple_grid_cascade_form_item_id == null
        )
        .forEach((item) => {
          if (item.dx_data_type == "number") {
            if (parametreFormData.value[item.parameter_name] == null) {
              dizi.push(item.label_text);
            }
          } else if (item.dx_data_type == "object") {
            if (parametreFormData.value[item.parameter_name] == null) {
              dizi.push(item.label_text);
            }
          } else {
            if (
              parametreFormData.value[item.parameter_name] == null ||
              parametreFormData.value[item.parameter_name] == ""
            ) {
              dizi.push(item.label_text);
            }
          }
        });

      if (dizi.length > 0) {
        hideToasts();
      }

      dizi.forEach((item) => {
        notify(
          {
            message: "[ " + item + " ] alanı gereklidir.",
            type: "error",
            displayTime: 15000,
          },
          { position: "bottom right", direction: "up-push" }
        );
      });
      return dizi.length == 0;
    }

    function onPopupHidden() {
      popupGridButtonId.value = null;
      popupDataItem.value = null;
      isPopupEnable.value = false;
    }

    onMounted(() => {});

    return {
      guid,
      tabPageId,
      dataGridId,
      loadPanelVisible,
      gridElement,
      gridItem,
      remoteOperations,
      masterDetailEnabled,
      masterDetailTabPageEnabled,
      exportEnabled,
      isDetail,

      parametreFormRef,
      isParametreForm,
      parametreFormData,
      parametreForms,
      parametreFormItems,
      sortingMode,
      isLoaded,
      columns,
      totalSummaryColumns,
      parametreFormButtons,
      headerButtonGroups,
      headerButtonGroupItems,
      rowButtonGroups,
      rowButtonGroupItems,
      columnButtons,
      columnDropDownButtons,
      toolbarButtons,
      toolbarDropDownButtons,
      dataSource,
      popupGridButtonId,
      popupGridButtonItem,
      popupDataItem,
      isPopupEnable,
      onToolbarPreparing,
      onRowExpanding,
      onRowDblClick,
      onExporting,
      onButtonDetayClick,
      onRowButtonGroupItemClick,
      onButtonClick,
      onGroupButtonItemClick,
      onRowPrepared,
      onCellPrepared,
      onRowButtonGroupVisible,
      onRowButtonGroupItemVisible,
      onButtonVisible,
      onPopupHidden,
      gridRefresh,      
      dataGridFormResult,
      masterGridItem,
      masterGridRowItem,
    };
  },
};
</script>

<style>
.dx-widget.dx-datagrid-pager.dx-pager {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
}
</style>
