<template>  
  <div ref="richEditElement"></div>
</template>

<script>
import {
  create,
  createOptions,
  ViewType,
  RichEditUnit,
  DocumentFormat  
} from "devexpress-richedit";
//import {DocumentFormat} from "devexpress-richedit/lib/core/document-format";
import { ref, reactive, onMounted,nextTick, watch } from "vue";
import CoreService from "@/services/core.service";

export default {
  props: {
    pFormData: { type: Object },
    pFields: { type: Object },
    pButtonItem: { type: Object },
  },
  components: {},

  setup(props) {
    
    const data = reactive(props.pFormData);
    const fields = reactive(props.pFields);
    let sablonData = {};

    const richEditElement = ref();
    var richEdit = null;
    var isTableCreated = false;

    onMounted(() => {

      setTimeout(() => {
        if (richEditElement.value) {
          initializeRichEdit();
        }
      }, 500); // 300ms gecikme

      nextTick(() => {          
       
        });
    });

    function initializeRichEdit() {
      try {
        const options = createOptions();
      options.unit = RichEditUnit.Centimeter;
      options.view.viewType = ViewType.PrintLayout;
      options.confirmOnLosingChanges.enabled = false;
      //options.width = "100vh";
      options.height = "80vh";

      options.events.calculateDocumentVariable = (s, e) => {        

        let subDocument = richEdit.selection.activeSubDocument;        

        //form item datasındaki bilgiler bindiriliyor
        if (data[e.variableName] != null) {

          e.value = data[e.variableName];          
          fields.forEach((f) => {
            if(f.parameter_name == e.variableName){

              if(f.is_lookup){
                  try{                
                  e.value = data[e.variableName+"_lookup_data"][f.lookup_display_field];
                  }catch(ex){
                    console.log("Lookup data display value alınırken hata oluştu:",ex);
                  }
              }
              else if (f.dx_editor_type == "dxDateBox"){
                  try{                  
                    e.value = data[e.variableName+"_date_box_display_value"];
                  }catch(ex){
                    console.log("DateBox display value alınırken hata oluştu:",ex);
                  }                
              }
            }
          });


        }

        //şablon tanımlarındaki sql_query sorgusundan gelen veriler bindiriliyor
        if (sablonData && sablonData[e.variableName] != null && typeof sablonData[e.variableName] === "string") {          

          if (sablonData[e.variableName].trim().startsWith("<table>")) {
            
            //table daha önce oluşturulmuş ise tekrar eklenmez. aksi durumda ekranda bir değişiklik olduğu zaman sürekli table append eder.
            if (isTableCreated) return;

            isTableCreated = true;
            // Stringi DOM'a dönüştürmek için geçici bir eleman kullanıyoruz
            let tempElement = document.createElement("div");
            tempElement.innerHTML = sablonData[e.variableName];

            // Tablonun kendisini seçiyoruz
            let t = tempElement.querySelector("table");
            let rowCount = t.rows.length;
            let collCount = t.rows[0].cells.length;
            

            let table = subDocument.tables.create(
              e.fieldInterval.start,
              collCount,
              rowCount
            );

            // Tablonun kenarlıklarını kaldırma
            table._table.properties.borders.bottomBorder.style = 0;
            table._table.properties.borders.topBorder.style = 0;
            table._table.properties.borders.leftBorder.style = 0;
            table._table.properties.borders.rightBorder.style = 0;
            table._table.properties.borders.insideHorizontalBorder.style = 0;
            table._table.properties.borders.insideVerticalBorder.style = 0;

            //table._table.preferredWidth.type =4;
            table._table.rows[0].cells[0].preferredWidth.type = 2;

            for (let i = 0, row; (row = t.rows[i]); i++) {
              for (let j = 0, col; (col = row.cells[j]); j++) {
                let cell = table._table.rows[i].cells[j];
                richEdit.document.insertText(
                  cell.interval.start,
                  col.innerHTML
                );
              }
            }
          } else if (sablonData[e.variableName].trim().startsWith('{\\')){
            var docvarProc = richEdit.createDocumentProcessor();     
            docvarProc.document.subDocuments.main.insertRtf(0, sablonData[e.variableName]);    
            e.value = docvarProc;
          }          
          else {
           e.value = sablonData[e.variableName];
          }
        }        
      };
      
      data["matbu_form_content_base64"] = null;

      options.events.documentChanged = function () {
        richEdit.exportToBase64(function (base64) {
          data.matbu_form_content_base64 = base64;          
        });
      };

      richEdit = create(richEditElement.value, options);

      let urlPrm = props.pButtonItem.dyn_data_grid_button_id;
      //butonun matbu form şablonunda tanımlı key field, form üzerindeki data nesnesinde bulunup şablon datası öyle alınıyor
      if (
        props.pButtonItem.dyn_matbu_form_sablon_key_field != null &&
        data[props.pButtonItem.dyn_matbu_form_sablon_key_field]
      ) {
        urlPrm += "/" + data[props.pButtonItem.dyn_matbu_form_sablon_key_field];
      }

      CoreService.get("GetMatbuFormSablon/" + urlPrm).then((result) => {
        const sablonItem = result.result.sablon_item[0];
        if (result.result.sablon_data) {
          sablonData = result.result.sablon_data[0];
        }

        richEdit.readOnly = sablonItem.read_only;
        richEdit.updateRibbon(function (ribbon) {
          //ribbon gizleniyor
          ribbon.visible = !sablonItem.read_only;
        });

        data["dyn_matbu_form_sablon_id"] = sablonItem.dyn_matbu_form_sablon_id;
        richEdit.openDocument(
          sablonItem.content,
          sablonItem.adi,
          DocumentFormat.OpenXml
        );        
         setTimeout(() => {
           richEdit.document.fields.updateAllFields();          
         }, 500);
      });

      props.pFields.forEach((f) => {
        watch(
          () => data[f.parameter_name],
          () => {
            richEdit.document.fields.updateAllFields();
          }
        );
      });
        
      } catch (error) {
        console.error("RichEdit initialization error:", error);
      }
    }

    return {
      richEditElement
    };
  },
};
</script>
