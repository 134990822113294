<template>
  <DxSelectBox
      :data-source="promptData"
      v-model="promptId"
      value-expr="dyn_ai_prompt_id"
      display-expr="prompt_name"
  />

  <DxTextArea
      v-model="userText"
      :min-height="100"
      :max-height="300"
      :auto-resize-enabled="true"
  />

  <DxButton 
    text="GÖNDER"
    type="default"
    :disabled="sendButtonDisabled"
      @click="onGonder"
  />     

  <div id="sqlEditor" style="height: 600px; width:100%;">
   
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import CoreService from "@/services/core.service";
import notify from "devextreme/ui/notify";
import DxSelectBox from 'devextreme-vue/select-box';
import DxTextArea from "devextreme-vue/text-area";
import DxButton from 'devextreme-vue/button'; 
import ace from 'ace-builds';
import 'ace-builds/src-noconflict/mode-sql';

export default {
components: {
  DxSelectBox,
  DxTextArea,
  DxButton
},

setup() {
  const promptData = ref();
  const promptId = ref();
  const userText = ref();
  const sendButtonDisabled = ref(false);
  let sqlEditor;
  
  CoreService.get("Ai/GetPrompts").then((result) => {        
      promptData.value = result.result;
    });

  function onGonder() {

    sendButtonDisabled.value = true;

    let data = {promptId:promptId.value ,userText:userText.value}
    
    CoreService.post("Ai/SendUserRequest/",
          data          
        ).then((result) => {
          sendButtonDisabled.value = false;
          
          if (result.success) {
            sqlEditor.setValue(result.result);
          } else {

            notify(
              {
                message: result.message,
                type: "error",
                displayTime: 10000,
                minWidth: 500,
              },
              { position: "bottom center", direction: "up-push" }
            );
          }
        });
  }

 

  onMounted(() => {
    sqlEditor = ace.edit("sqlEditor")
    sqlEditor.session.setMode("ace/mode/sql");
    sqlEditor.setFontSize(16);
  });
  
  return {  
    promptData,
    promptId,
    userText,
    sendButtonDisabled,
    onGonder    
  };
},
};
</script>

<style>
#editor {
border: 1px solid #ccc;
}
</style>